@import '../../../node_modules/@clout-team/web-components/build/assets/styles/mixins';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.pr_profile {
  &_header {
    border: none;
    padding: 0;
    h2 {
      color: var(--text-100);
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
  }

  &__avatar {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &_btn {
      margin-top: 11px;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #5B53CC;
      @include is-mobile() {
        color: var(--text-100);
      }
      height: 20px;
      cursor: pointer;
    }
    @include is-mobile() {
      padding: 16px 32px 0;
      margin-bottom: 0;
    }
  }
  &__input_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 400px;
    margin-bottom: 32px;
  }
  &__input {
    width: 100%;

    .form-item input {
      border-radius: 8px;
      border-color: var(--stroke-opacity) !important;
    }

    .inp_title {
      color: var(--text-80);
    }

    .form-field__error {
      color: var(--ui-red);
      font: $metadata;
    }

    .inp_auth_input.is-invalid {
      .form-item {
        input:focus {
          border-color: #d83b01 !important;
          // box-shadow: none !important;
        }
      }
    }

    &.isNotValid {
      .form-item {
        input {
          border-color: #d83b01 !important;
        }
      }
    }
  }
  &__birthday {
    margin-bottom: 24px;
    width: 100%;
    & span {
      color: var(--text-left-80);
      @include is-mobile() {
        color: var(--text-80);
      }
    }
    .layout-gap-lg {
      flex-direction: column;
      gap: 11px;
      width: 100%;
      .small-day, .middle-day {
        width: 100%;
      }
      label {
        span {
          color: var(--text-80);
          font: $footnote;
        }
        .form-item input {
          border-radius: 8px;
          border-color: var(--stroke-opacity) !important;        }
      }
    }
  }
  &__buttons {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &_send {
      @include is-mobile() {
        margin-bottom: 0;
      }
    }
    .btn {
      border-radius: 8px;
    }
  }
  &_wrapper_description {
    position: fixed;
    bottom: 48px;
    max-width: 416px;
      p {
        color: var(--text-80);
      }
  }
  &__description {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: var(--text-left-80);
    @include is-mobile() {
      color: var(--text-80);
    }
    & > span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @include is-mobile() {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__input_wrapper, &__buttons, &__input, &__top, &__birthday {
      width: 100%;
    }

    &__birthday {
      .birthday-component {
        width: 100%;
        display: block;
      }
    }

    &__input_wrapper {
      flex-direction: column;
    }
  }
}
.pr_placeholder_icon {
  background: var(--surface-1);
}

.pr_profile__avatar_btn {
  border-radius: 8px !important;
}

.pr_profile_name {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pr_profile_footer {
  width: 100%;
  padding: 16px;
}

.pr_profile_form {
  width: 100%;
  flex-grow: 1;
}

.pr_profile__set-birthday {
  padding: 16px;

  input {
    height: 44px;
  }
}